import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['buttons', 'orderId'];

  connect() {
    window.paypal.Buttons({
      createOrder: this._createOrder.bind(this),
      onApprove: this._onApprove.bind(this)
    }).render('[data-target="paypal.buttons"]');
  }

  _createOrder(data, actions) {
    return actions.order.create({
      intent: 'CAPTURE',
      payer: {
        name: {
          given_name: this.data.get('first-name'),
          surname: this.data.get('last-name')
        },
        email_address: this.data.get('email')
      },
      purchase_units: [{
        amount: { value: this.data.get('amount'), currency_code: 'GBP' }
      }]
    });
  }

  _onApprove(data, _actions) {
    this.orderIdTarget.value = data.orderID;
    this.element.submit();
  }
}
