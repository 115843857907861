import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['days', 'hours', 'minutes', 'seconds'];

  connect() {
    this.remaining = parseInt(this.data.get('remaining') * 1000);
    this.countTo = new Date(Date.now() + this.remaining);
    this.update();
    this.timer = setInterval(this.update.bind(this), 1000);
  }

  disconnect() {
    clearTimeout(this.timer);
  }

  update() {
    const remaining = timeRemaining(this.countTo, this.offset);
    if (remaining.total < 0) {
      clearTimeout(this.timer);
      this.updateUI({ days: 0, hours: 0, minutes: 0, seconds: 0 })
    } else {
      this.updateUI(remaining);
    }
  }

  updateUI(remaining) {
    let days = remaining.days;
    let hours = remaining.hours;
    let minutes = remaining.minutes;
    let seconds = remaining.seconds;
    
    if (this.hasDaysTarget) {
      this.daysTarget.textContent = days;
    } else {
      hours = hours + (days * 24);
    }

    if (this.hasHoursTarget) {
      this.hoursTarget.textContent = ('0' + hours).slice(-2);
    } else {
      minutes = minutes + (hours * 60);
    }

    if (this.hasMinutesTarget) { 
      this.minutesTarget.textContent = ('0' + minutes).slice(-2);
    } else {
      seconds = seconds + (minutes * 60);
    }

    this.secondsTarget.textContent = ('0' + seconds).slice(-2);
  }
}

function timeRemaining(upTo) {
  const total = upTo - Date.now();
  const seconds = Math.floor( (total/1000) % 60 );
  const minutes = Math.floor( (total/1000/60) % 60 );
  const hours = Math.floor( (total/(1000*60*60)) % 24 );
  const days = Math.floor( total/(1000*60*60*24) );

  return { total, days, hours, minutes, seconds }
}
