import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['toggle'];

  toggle() {
    this.toggleTargets.forEach((target) => {
      const domClass = target.dataset.togglerClass;
      target.classList.toggle(domClass);
    })
  }
}
