import { Controller } from "stimulus"

// fra controller, when fra member is true, show membership number field
export default class extends Controller {
  connect() {
    this.isMemberInput = document.getElementById('participant_fra_member_true');
    this.isNotMemberInput = document.getElementById('participant_fra_member_false');
    this.membershipNumber = document.getElementById('participant_fra_membership_number').closest('.form-group');

    this.isMemberInput.addEventListener('click', this.update.bind(this));
    this.isNotMemberInput.addEventListener('click', this.update.bind(this));
    this.update();
  }

  update() {
    setActive(this.membershipNumber, this.isMemberInput.checked);
  }
}

function setActive(el, on) {
  el.hidden = !on;
  [ ...el.childNodes ].forEach((child) => child.disabled = !on);
}

