import { Controller } from "stimulus"

const dropin = require('braintree-web-drop-in');

export default class extends Controller {
  static targets = ['dropin', 'nonce', 'submit', 'wait', 'container'];

  connect() {
    this.submitTarget.disabled = true;
    this.waitTarget.hidden = false;

    dropin.create({
      authorization: this.data.get('client-token'),
      container: '[data-target="braintree.container"]',
      threeDSecure: true,
      card: {
        cardholderName: { required: true }
      },
      paypal: {
        flow: 'checkout',
        amount: this.data.get('amount'),
        currency: 'GBP'
      }
    }, this._initializeBraintree.bind(this))
  }

  _initializeBraintree(clientErr, instance) {
    if (clientErr) {
      console.error('Error creating client:', clientErr);
      return;
    }

    this.submitTarget.disabled = false;
    this.waitTarget.hidden = true;

    this.element.addEventListener('submit', (event) => {
      event.preventDefault();
      const submitText = this.submitTarget.innerText;
      this.submitTarget.innerText = "Submitting... please wait";
      this.submitTarget.disabled = true;

      const threeDSecure = { amount: this.data.get('amount') }

      instance.requestPaymentMethod({ threeDSecure: threeDSecure }, (err, payload) => {
        if (err) {
          console.error('Error requesting payment:', err);
          this.submitTarget.disabled = false;
          this.submitTarget.innerText = submitText;
        } else {
          this.nonceTarget.value = payload.nonce;
          this.element.submit();
        }
      })
    })
  }
}
